// 引入创建配置函数
// 引入创建配置函数
import {
  fwzyQlxxForm,
  fwzyQlxxExForm,
  fwbgQlxxForm,
  fwzxQlxxForm,
  fwcfQlxxForm,
  fwcfQlxxExForm,
  fwjfQlxxExForm,
  ygdyQlxxForm,
  mmygQlxxForm,
  fwscQlxxForm,
  mmygQlxxExForm,
  esfmmygQlxxExForm,
  mmygzxQlxxExForm,
  dyygQlxxExForm,
  dyygzxQlxxExForm,
  djygdjdyDialogForm,
  ybdyqscQlxxExForm,
  zjdyqscQlxxExForm,
  fwscQlxxDialogForm,
  qlxxDialogForm,
  ygdyzxQlxxExForm,
  otherqlxxDialogForm,
  mmygdjdyDialogForm,
  // ygqlxxDialogForm,
  mmygqlxxDialogForm,
  mmygqlxxDialogFormN,
  mmygdjdyQlxxForm,
  mmygdyzxQlxxForm,
  tddyqlxxDialogForm,
  tddyzyqlxxDialogForm,
  tddyqscQlxxForm,
  tddyqzxQlxxForm,
  fwzyycQlxxForm,
  fwzyycQlxxFormN200104,
  fwzyycQlxxExForm,
  zjdsyqscdj,
  ybdyqscForm,
  ybdyscdyqkForm,
  zjdsyqzydjQlxxExForm,
  GyjsydSyqScQlxxForm,
  GyjsydSyqScQtQlxxForm,
  ZshzdjQlxxForm,
  syqzyQlxxForm,
  mmygzxQlxxForm,
  mmygzxdjQlxxExForm,
  esfmmygqlxxDialogForm,
  qyfwzyycQlxxForm,
  qyfwbgQlxxForm,
  qyfwzxQlxxForm,
  qymmygdyQlxxForm,
  DyxxQlxxExForm,
  MmygdjQlxxExForm,
  DyygdjQlxxExForm,
  qymmygqlxxDialogForm,
  dyqscForm,
  dyqzxForm,
  dyqzxExForm,
  GyjsFwsyqScdjQlxxForm,
  GyjsFwsyqScdjQyQlxxForm,
  ysspfdyygQlxxExForm,
  ysspfdyygQyQlxxExForm,
  dyygdjQlxxExMhsExForm,
  tdjyqQlxxForm,
  ldsyqscdjQlxxForm,
  ldsyqjslldscdjQlxxForm,
  dyghDyxxExForm,
  tdjyqqlxxDialogForm,
} from './qlxxFormConfig';
import {
  qlrDialogForm,
  dyqrDialogForm,
  ywrDialogForm,
  zwrDialogForm,
  fwzyQlrDialogForm,
  fwzyYwrDialogForm,
  fwzydyYwrDialogForm,
  fbfDialogForm,
  fbfdlrDialogForm,
  cbfDialogForm,
  lyjyzDialogForm,
} from './sqrDialogFormConfig';
import { familyGroupColumn } from './familyColumnConfig';

// import createTableConfig from '@public/applyConfig/createTableConfig';
import column from './tableColumnConfig';

const copyData = (form) => {
  return form.map((e) => {
    if (typeof e === 'object') {
      return Object.assign({}, e);
    } else {
      return e;
    }
  });
};
let vm = null;
export const getVm = (_this) => {
  // console.log(_this,'1')
  vm = _this;
};
export const sqbConfig = [
  {
    // 一般抵押权首次（ddcome@Right）
    name: '抵押权首次登记',
    code: 'N100301',
    data: [
      {
        name: '抵押权首次登记',
        code: 'N100301',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: dyqscForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: ybdyqscQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押权首次（土地）
    name: '抵押权首次（土地）',
    code: 'N200111',
    data: [
      {
        name: '抵押权首次（土地）',
        code: 'N200111',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(土地所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.tddyqQlxxTable,
            dialogFormConfig: tddyqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: tddyqlxxDialogForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: tddyqscQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押权转移（土地）
    name: '抵押权转移（土地）',
    code: 'N200112',
    data: [
      {
        name: '抵押权转移（土地）',
        code: 'N200112',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(土地所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.tddyqQlxxTable,
            dialogFormConfig: tddyzyqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: tddyzyqlxxDialogForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: tddyqscQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 国有建设用地使用权及房屋所有权首次登记  *
    name: '国有建设用地使用权及房屋所有权首次登记',
    code: 'N100104',
    data: [
      {
        name: '国有建设用地使用权及房屋所有权首次登记',
        code: 'N100104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: GyjsFwsyqScdjQyQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: GyjsFwsyqScdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其他情况',
            formConfig: GyjsydSyqScQtQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 国有建设用地使用权首次登记
    name: '国有建设用地使用权首次登记',
    code: 'N100103',
    data: [
      {
        name: '国有建设用地使用权首次登记',
        code: 'N100103',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          // {
          //   type: 'form',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况',
          //   formConfig: GyjsydSyqScQlxxForm,
          // },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其他情况',
            formConfig: GyjsydSyqScQtQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押权注销（ddcome@Right）
    name: '抵押权注销',
    code: 'N400301',
    data: [
      {
        name: '抵押权注销',
        code: 'N400301',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: dyqzxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: dyqzxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押权注销（土地）
    name: '抵押权注销（土地）',
    code: 'N100303',
    data: [
      {
        name: '抵押权注销（土地）',
        code: 'N100303',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(土地所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.tddyqQlxxTable,
            dialogFormConfig: tddyqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: tddyqlxxDialogForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: tddyqzxQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 房屋转移
    name: '房屋转移',
    code: 'N200104',
    data: [
      {
        name: '房屋转移',
        code: 'N200104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '买方',
                tableColumnConfig: column.qlrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '卖方',
                tableColumnConfig: column.ywrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyYwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwzyycQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            // formConfig: fwzyycQlxxForm,
            formConfig: fwzyycQlxxFormN200104,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '房屋转移信息',
            formConfig: fwzyycQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 房屋变更
    name: '房屋变更',
    code: 'N300104',
    data: [
      {
        name: '房屋变更',
        code: 'N300104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwbgQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 房屋首次
    name: '房屋首次',
    code: 'N100401',
    data: [
      {
        name: '房屋首次',
        code: 'N100401',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.fwscQlxxTable,
            dialogFormConfig: fwscQlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwscQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 房屋注销
    name: '房屋注销',
    code: 'N400104',
    data: [
      {
        name: '房屋注销',
        code: 'N400104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwzxQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwzxQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 房屋更正
    name: '房屋更正',
    code: 'N500104',
    data: [
      {
        name: '房屋更正',
        code: 'N500104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwbgQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '房屋查封登记',
    code: 'N100104',
    data: [
      {
        name: '房屋查封登记',
        code: 'N100104',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '12',
                tableTitle: '被查封人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwcfQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '查封信息',
            formConfig: fwcfQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '房屋解封登记',
    code: 'N400402',
    data: [
      {
        name: '房屋解封登记',
        code: 'N400402',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '12',
                tableTitle: '被查封人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwcfQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '解封信息',
            formConfig: fwjfQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 预售商品房买卖预告登记
    name: '预售商品房买卖预告登记',
    code: 'N100201',
    data: [
      {
        name: '预售商品房买卖预告登记',
        code: 'N100201',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '预告权利人(购房者)',
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '8',
                tableTitle: '预告义务人(房地产开发商)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.ygqlxxTable,
            dialogFormConfig: qymmygqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: mmygqlxxDialogForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '预告信息',
            formConfig: mmygQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 二手房买卖预告登记
    name: '二手房买卖预告登记',
    code: 'N100211',
    data: [
      {
        name: '二手房买卖预告登记',
        code: 'N100211',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '预告权利人(买方)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '8',
                tableTitle: '预告义务人(卖方)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.ygqlxxTable,
            dialogFormConfig: qymmygqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: esfmmygqlxxDialogForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '预告信息',
            formConfig: esfmmygQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 预告变更登记
    name: '预售商品房买卖预告变更登记',
    code: 'N200204',
    data: [
      {
        name: '预售商品房买卖预告变更登记',
        code: 'N200204',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '预告权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwbgQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 预售商品房买卖预告转移登记
    name: '预售商品房买卖预告转移登记',
    code: 'N200203',
    data: [
      {
        name: '预售商品房买卖预告转移登记',
        code: 'N200203',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                // familyTableConfig: column.familyGroupTable,
                // familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '8',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                // familyTableConfig: column.familyGroupTable,
                // familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyYwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qyfwzyycQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwzyycQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '房屋转移信息',
            formConfig: fwzyycQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 预售商品房买卖预告注销登记
    name: '预售商品房买卖预告注销登记',
    code: 'N360000',
    data: [
      {
        name: '预售商品房买卖预告注销登记',
        code: 'N360000',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '预告权利人(购房者)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '8',
                tableTitle: '预告义务人(房地产开发商)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况及预告信息',
            tableColumnConfig: column.ygqlxxTable,
            dialogFormConfig: mmygzxdjQlxxExForm, // 表单配置
          },
          //   {
          //     type: 'table-qlxx',
          //     key: 'qlxxExMhs',
          //     tableTitle: '不动产情况',
          //     tableColumnConfig: copyData(column.ygqlxxTable),
          //     dialogFormConfig: copyData(qymmygdyQlxxForm) // 表单配置
          //   },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(mmygQlxxForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '预告信息',
            formConfig: mmygzxQlxxExForm,
          },
          // {
          //   type: 'form',
          //   key: 'qlxxEx',
          //   tableTitle: '预告信息',
          //   formConfig: mmygzxQlxxExForm // 表单配置
          // }
        ],
      },
    ],
  },
  {
    // 预售商品房抵押预告登记 叶
    name: '预售商品房抵押预告登记',
    code: 'N100203',
    data: [
      {
        name: '预售商品房抵押预告登记',
        code: 'N100203',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '9',
                tableTitle: '预告抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '10',
                tableTitle: '预告抵押人(购房者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '11',
                tableTitle: '预告债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.ygqlxxTable,
            dialogFormConfig: qymmygdyQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ygdyQlxxForm,
          },
          //   {
          //     type: 'table-qlxx',
          //     key: 'qlxxEx',
          //     tableTitle: '抵押信息',
          //     formConfig: ysspfdyygQyQlxxExForm // 表单配置
          //   },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: ysspfdyygQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 预售商品房抵押权预告注销
    name: '预售商品房抵押权预告注销',
    code: 'N300126',
    data: [
      {
        name: '预售商品房抵押权预告注销',
        code: 'N300126',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '9',
                tableTitle: '预告抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '10',
                tableTitle: '预告抵押人(购房者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '11',
                tableTitle: '预告债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.ygqlxxTable,
            dialogFormConfig: qyfwbgQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: mmygdyzxQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: ygdyzxQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '房屋所有权变更登记+房屋所有权转移登记', // *
    code: 'H999003',
    data: [
      {
        name: '所有权变更登记',
        code: 'H999003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: copyData(ZshzdjQlxxForm), // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(ZshzdjQlxxForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: copyData(fwzyQlxxExForm), // 表单配置
          },
        ],
      },
      {
        name: '所有权转移登记',
        code: 'H999003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '买方',
                tableColumnConfig: column.qlrTable,
                // familyTableConfig: column.familyGroupTable,
                // familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '卖方',
                tableColumnConfig: column.ywrTable,
                // familyTableConfig: column.familyGroupTable,
                // familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyYwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: ZshzdjQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(ZshzdjQlxxForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: copyData(fwzyQlxxExForm), // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '房屋所有权转移登记+抵押权首次登记', // *
    code: 'H999002',
    data: [
      {
        name: '所有权转移登记',
        code: 'H999002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '买方',
                tableColumnConfig: column.qlrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '卖方',
                tableColumnConfig: column.ywrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzydyYwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: ZshzdjQlxxForm, // 表单配置

          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ZshzdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
      {
        name: '抵押权首次登记',
        code: 'H999002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          // {
          //   type: 'table-qlxx',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况',
          //   tableColumnConfig: column.dyscqlxxTable,
          //   dialogFormConfig: otherqlxxDialogForm, // 表单配置
          // },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ZshzdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: DyxxQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押变更登记
    name: '房屋抵押权变更登记',
    code: 'N300301',
    data: [
      {
        name: '房屋抵押权变更登记',
        code: 'N300301',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: dyqscForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: ybdyqscQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '买卖预告登记+抵押预告登记', // *
    code: 'H999001',
    data: [
      {
        name: '买卖预告登记',
        code: 'N100201',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '7',
                tableTitle: '预告权利人(购房者)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '8',
                tableTitle: '预告义务人(房地产开发商)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyygdjqlxxTable,
            dialogFormConfig: mmygdjdyDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(mmygdjdyQlxxForm),
          },

          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '预告信息',
            formConfig: copyData(MmygdjQlxxExForm), // 表单配置
          },
        ],
      },
      {
        name: '抵押预告登记',
        code: 'N100203',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '9',
                tableTitle: '预告抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '10',
                tableTitle: '预告抵押人(购房者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '11',
                tableTitle: '预告债务人(借款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyygdjqlxxExhTable,
            dialogFormConfig: djygdjdyDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(dyygdjQlxxExMhsExForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: copyData(DyygdjQlxxExForm), // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 在建工程抵押首次
    name: '在建工程抵押首次',
    code: 'N300099',
    data: [
      {
        name: '在建工程抵押首次',
        code: 'N300099',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人(贷款者)',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyygdjqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: zjdyqscQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权首次登记
  {
    name: '宅基地使用权及房屋所有权首次登记',
    code: 'N100106',
    data: [
      {
        name: '宅基地使用权及房屋所有权首次登记',
        code: 'N100106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, //表单配置
              },
            ],
          },
          // {
          //   type: 'table-sqr',
          //   category: '0',
          //   tableTitle: '权利人',
          //   tableColumnConfig: column.qlrTable,
          //   dialogFormConfig: qlrDialogForm // 表单配置
          // },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.fwscQlxxTable,
            dialogFormConfig: fwscQlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwscQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权转移登记
  {
    name: '宅基地使用权及房屋所有权转移登记',
    code: 'N200106',
    data: [
      {
        name: '宅基地使用权及房屋所有权转移登记',
        code: 'N200106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '买方',
                tableColumnConfig: column.qlrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '卖方',
                tableColumnConfig: column.ywrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyYwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: fwzyycQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwzyycQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '房屋转移信息',
            formConfig: fwzyycQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权变更登记
  {
    name: '宅基地使用权及房屋所有权变更登记',
    code: 'N300106',
    data: [
      {
        name: '宅基地使用权及房屋所有权变更登记',
        code: 'N300106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(fwbgQlxxForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: copyData(fwzyQlxxExForm), // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权更正登记
  {
    name: '宅基地使用权及房屋所有权更正登记',
    code: 'N500106',
    data: [
      {
        name: '宅基地使用权及房屋所有权更正登记',
        code: 'N500106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                tableTitle: '权利人',
                category: '0',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm,
              },
              {
                tableTitle: '原权利人',
                category: '1',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm,
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: copyData(fwbgQlxxForm),
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: copyData(fwzyQlxxExForm), // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权注销登记
  {
    name: '宅基地使用权及房屋所有权注销登记',
    code: 'N400106',
    data: [
      {
        name: '宅基地使用权及房屋所有权注销登记',
        code: 'N400106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwzxQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权补证登记
  {
    name: '宅基地使用权及房屋所有权补证登记',
    code: 'N600106',
    data: [
      {
        name: '宅基地使用权及房屋所有权补证登记',
        code: 'N600106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权及房屋所有权换证登记
  {
    name: '宅基地使用权及房屋所有权换证登记',
    code: 'N700106',
    data: [
      {
        name: '宅基地使用权及房屋所有权换证登记',
        code: 'N700106',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: qlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权首次登记（土地）
  {
    name: '宅基地使用权首次登记',
    code: 'N100105',
    data: [
      {
        name: '宅基地使用权首次登记',
        code: 'N100105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          // {
          //   type: 'table-qlxx',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况',
          //   tableColumnConfig: column.qlxxTable,
          //   dialogFormConfig: qlxxDialogForm // 表单配置
          // },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqscdj,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权转移登记（土地）
  {
    name: '宅基地使用权转移登记',
    code: 'N200105',
    data: [
      {
        name: '宅基地使用权转移登记',
        code: 'N200105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqzydjQlxxExForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权变更登记（土地）
  {
    name: '宅基地使用权变更登记',
    code: 'N300105',
    data: [
      {
        name: '宅基地使用权变更登记',
        code: 'N300105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqzydjQlxxExForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权更正登记（土地）
  {
    name: '宅基地使用权更正登记',
    code: 'N500105',
    data: [
      {
        name: '宅基地使用权更正登记',
        code: 'N500105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqzydjQlxxExForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权注销登记（土地）
  {
    name: '宅基地使用权注销登记',
    code: 'N400105',
    data: [
      {
        name: '宅基地使用权注销登记',
        code: 'N400105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqzydjQlxxExForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  // 宅基地使用权补证登记（土地）
  {
    name: '宅基地使用权补证登记',
    code: 'N600105',
    data: [
      {
        name: '宅基地使用权补证登记',
        code: 'N600105',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: zjdsyqzydjQlxxExForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },

  // 证书补证登记（土地） *
  {
    name: '证书补证登记',
    code: 'N900101',
    data: [
      {
        name: '证书补证登记',
        code: 'N900101',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.qlxxTable,
            dialogFormConfig: ZshzdjQlxxForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ZshzdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },

  // 证书换证登记（土地） *
  {
    name: '证书换证登记',
    code: 'N900102',
    data: [
      {
        name: '证书换证登记',
        code: 'N900102',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: qlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '原权利人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ZshzdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '组合贷',
    code: 'H100311',
    data: [
      {
        name: '公积金抵押',
        code: 'N100301',
        config: [
          {
            type: 'table-sqr',
            category: '4',
            tableTitle: '抵押权人(一)',
            tableColumnConfig: column.qlrTable,
            dialogFormConfig: dyqrDialogForm, // 表单配置
          },
          {
            type: 'table-sqr',
            category: '5',
            tableTitle: '抵押人(房屋所有者)',
            tableColumnConfig: column.ywrTable,
            dialogFormConfig: ywrDialogForm, // 表单配置
          },
          {
            type: 'table-sqr',
            category: '6',
            tableTitle: '债务人',
            tableColumnConfig: column.zwrTable,
            dialogFormConfig: zwrDialogForm, // 表单配置
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况(单位:平方米、万元)',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况(单位:平方米、万元)',
            formConfig: fwbgQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            evaluation: true, // 给表单标题重新命名
            tableTitle: '抵押信息一(单位:平方米、万元)',
            formConfig: ybdyqscQlxxExForm, // 表单配置
          },
        ],
      },
      {
        name: '银行抵押',
        code: 'N100301',
        config: [
          {
            type: 'table-sqr',
            category: '4',
            tableTitle: '抵押权人(二)',
            tableColumnConfig: column.qlrTable,
            dialogFormConfig: dyqrDialogForm, // 表单配置
          },
          {
            type: 'table-sqr',
            category: '5',
            tableTitle: '抵押人(房屋所有者)',
            tableColumnConfig: column.ywrTable,
            dialogFormConfig: ywrDialogForm, // 表单配置
          },
          {
            type: 'table-sqr',
            category: '6',
            tableTitle: '债务人',
            tableColumnConfig: column.zwrTable,
            dialogFormConfig: zwrDialogForm, // 表单配置
          },
          // {
          //   type: 'table-qlxx',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况(单位:平方米、万元)',
          //   tableColumnConfig: column.dyscqlxxTable,
          //   dialogFormConfig: otherqlxxDialogForm // 表单配置
          // },
          // {
          //   type: 'form',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况(单位:平方米、万元)',
          //   formConfig: fwbgQlxxForm
          // },
          {
            type: 'form',
            key: 'qlxxEx',
            evaluation: true, // 给表单标题重新命名
            tableTitle: '抵押信息二(单位:平方米、万元)',
            formConfig: ybdyqscQlxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 抵押变更登记
    name: '土地承包经营权',
    code: 'F400200',
    data: [
      {
        name: '土地承包经营权',
        code: 'F400200',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人(承包方代表及代理人，法定代表人)',
                tableColumnConfig: column.cbfTable,
                dialogFormConfig: cbfDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人(发包方)',
                tableColumnConfig: column.fbfTable,
                dialogFormConfig: fbfDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.tdqlxx,
            dialogFormConfig: tdjyqqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: tdjyqQlxxForm,
          },
        ],
      },
    ],
  },
  {
    // 土地经营权-首次登记
    name: '土地经营权',
    code: 'F500200',
    data: [
      {
        name: '土地经营权',
        code: 'F500200',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人(承包方代表及代理人，法定代表人)',
                tableColumnConfig: column.cbfTable,
                dialogFormConfig: cbfDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人(发包方)',
                tableColumnConfig: column.fbfTable,
                dialogFormConfig: fbfDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.tdqlxx,
            dialogFormConfig: tdjyqqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: tdjyqQlxxForm,
          },
        ],
      },
    ],
  },
  {
    // 林地使用权首次登记
    name: '林地使用权首次登记',
    code: 'N510001',
    data: [
      {
        name: '林地使用权首次登记',
        code: 'N510001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权转移登记
    name: '林地使用权转移登记',
    code: 'N510002',
    data: [
      {
        name: '林地使用权转移登记',
        code: 'N510002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权更正登记
    name: '林地使用权更正登记',
    code: 'N510003',
    data: [
      {
        name: '林地使用权更正登记',
        code: 'N510003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权变更登记
    name: '林地使用权变更登记',
    code: 'N510004',
    data: [
      {
        name: '林地使用权变更登记',
        code: 'N510004',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权注销登记
    name: '林地使用权注销登记',
    code: 'N510005',
    data: [
      {
        name: '林地使用权注销登记',
        code: 'N510005',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    name: '房屋所有权转移登记+抵押权变更登记（同行带押过户）', // *
    code: 'H999006',
    data: [
      {
        name: '所有权转移登记',
        code: 'H999006',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '买方',
                tableColumnConfig: column.qlrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyQlrDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '卖方',
                tableColumnConfig: column.ywrTable,
                familyTableConfig: column.familyGroupTable,
                familyColumnConfig: familyGroupColumn,
                dialogFormConfig: fwzyYwrDialogForm, // 表单配置
              },
            ],
          },
          // {
          //   type: 'table-qlxx',
          //   key: 'qlxxExMhs',
          //   tableTitle: '不动产情况',
          //   tableColumnConfig: column.qlxxTable,
          //   dialogFormConfig: qlxxDialogForm, // 表单配置
          // },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: ZshzdjQlxxForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '其它信息',
            formConfig: fwzyQlxxExForm, // 表单配置
          },
        ],
      },
      {
        name: '抵押权首次登记',
        code: 'H999006',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '4',
                tableTitle: '抵押权人(银行)',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: dyqrDialogForm, // 表单配置
              },
              {
                category: '5',
                tableTitle: '抵押人(房屋所有者)',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
              {
                category: '6',
                tableTitle: '债务人',
                tableColumnConfig: column.zwrTable,
                dialogFormConfig: zwrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'table-qlxx',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            tableColumnConfig: column.dyscqlxxTable,
            dialogFormConfig: otherqlxxDialogForm, // 表单配置
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '不动产情况',
            formConfig: dyqscForm,
          },
          {
            type: 'form',
            key: 'qlxxEx',
            tableTitle: '抵押信息',
            formConfig: dyghDyxxExForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权及森林、林木使用权首次登记
    name: '林地使用权及森林、林木使用权首次登记',
    code: 'N610001',
    data: [
      {
        name: '林地使用权及森林、林木使用权首次登记',
        code: 'N610001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权及森林、林木使用权转移登记
    name: '林地使用权及森林、林木使用权转移登记',
    code: 'N610002',
    data: [
      {
        name: '林地使用权及森林、林木使用权转移登记',
        code: 'N610002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权及森林、林木使用权更正登记
    name: '林地使用权及森林、林木使用权更正登记',
    code: 'N610003',
    data: [
      {
        name: '林地使用权及森林、林木使用权更正登记',
        code: 'N610003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权及森林、林木使用权变更登记
    name: '林地使用权及森林、林木使用权变更登记',
    code: 'N610004',
    data: [
      {
        name: '林地使用权及森林、林木使用权变更登记',
        code: 'N610004',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权及森林、林木使用权注销登记
    name: '林地使用权及森林、林木使用权注销登记',
    code: 'N610005',
    data: [
      {
        name: '林地使用权及森林、林木使用权注销登记',
        code: 'N610005',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/森林、林木使用权首次登记
    name: '林地使用权/森林、林木使用权首次登记',
    code: 'N610001',
    data: [
      {
        name: '林地使用权/森林、林木使用权首次登记',
        code: 'N610001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '森林及林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地承包经营权及林木所有权首次登记
    name: '林地承包经营权及林木所有权首次登记',
    code: 'N710001',
    data: [
      {
        name: '林地承包经营权及林木所有权首次登记',
        code: 'N710001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地承包经营权及林木所有权转移登记
    name: '林地承包经营权及林木所有权转移登记',
    code: 'N710002',
    data: [
      {
        name: '林地承包经营权及林木所有权转移登记',
        code: 'N710002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地承包经营权及林木所有权更正登记
    name: '林地承包经营权及林木所有权更正登记',
    code: 'N710003',
    data: [
      {
        name: '林地承包经营权及林木所有权更正登记',
        code: 'N710003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地承包经营权及林木所有权变更登记
    name: '林地承包经营权及林木所有权变更登记',
    code: 'N710004',
    data: [
      {
        name: '林地承包经营权及林木所有权变更登记',
        code: 'N710004',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地承包经营权及林木所有权注销登记
    name: '林地承包经营权及林木所有权注销登记',
    code: 'N710005',
    data: [
      {
        name: '林地承包经营权及林木所有权注销登记',
        code: 'N710005',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权首次登记
    name: '林地经营权及林木所有权首次登记',
    code: 'N810001',
    data: [
      {
        name: '林地经营权及林木所有权首次登记',
        code: 'N810001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权转移登记
    name: '林地经营权及林木所有权转移登记',
    code: 'N810002',
    data: [
      {
        name: '林地经营权及林木所有权转移登记',
        code: 'N810002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权更正登记
    name: '林地经营权及林木所有权更正登记',
    code: 'N810003',
    data: [
      {
        name: '林地经营权及林木所有权更正登记',
        code: 'N810003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权变更登记
    name: '林地经营权及林木所有权变更登记',
    code: 'N810004',
    data: [
      {
        name: '林地经营权及林木所有权变更登记',
        code: 'N810004',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权补证换证登记
    name: '林地经营权及林木所有权补证换证登记',
    code: 'N810005',
    data: [
      {
        name: '林地经营权及林木所有权补证换证登记',
        code: 'N810005',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地经营权及林木所有权注销登记
    name: '林地经营权及林木所有权注销登记',
    code: 'N810006',
    data: [
      {
        name: '林地经营权及林木所有权注销登记',
        code: 'N810006',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/林木所有权---首次登记
    name: '林地使用权及林木所有权首次登记',
    code: 'N910001',
    data: [
      {
        name: '林地使用权及林木所有权首次登记',
        code: 'N910001',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/林木所有权---转移登记
    name: '林地使用权及林木所有权转移登记',
    code: 'N910002',
    data: [
      {
        name: '林地使用权及林木所有权转移登记',
        code: 'N910002',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
              {
                category: '1',
                tableTitle: '义务人',
                tableColumnConfig: column.ywrTable,
                dialogFormConfig: ywrDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/林木所有权---更正登记
    name: '林地使用权及林木所有权更正登记',
    code: 'N910003',
    data: [
      {
        name: '林地使用权及林木所有权更正登记',
        code: 'N910003',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/林木所有权---变更登记
    name: '林地使用权及林木所有权变更登记',
    code: 'N910004',
    data: [
      {
        name: '林地使用权及林木所有权变更登记',
        code: 'N910004',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
  {
    // 林地使用权/林木所有权---注销登记
    name: '林地使用权及林木所有权注销登记',
    code: 'N910005',
    data: [
      {
        name: '林地使用权及林木所有权注销登记',
        code: 'N910005',
        config: [
          {
            type: 'table-sqr',
            data: [
              {
                category: '0',
                tableTitle: '权利人',
                tableColumnConfig: column.qlrTable,
                dialogFormConfig: lyjyzDialogForm, // 表单配置
              },
            ],
          },
          {
            type: 'form',
            key: 'qlxxExMhs',
            tableTitle: '林地信息',
            formConfig: ldsyqjslldscdjQlxxForm, // 表单配置
          },
        ],
      },
    ],
  },
];
export default sqbConfig;
